<script setup lang="ts">
import { storeToRefs } from "pinia";
import AccessGroupCustomerEnum from "~/models/AccessGroupCustomerEnum";
import UrlConstants from "~/config/constants/UrlConstants";
import AnybillAnalytics from "~/utils/AnybillAnalytics";
import { AnalyticsEventType } from "~/config/constants/analytics/AnalyticsEventType";
import { AnalyticsInteractionEvent } from "~/config/constants/analytics/AnalyticsInteractionEvent";
import { AnalyticsRedirectTarget } from "~/config/constants/analytics/AnalyticsRedirectTarget";
import { useUserModule } from "~/stores/user";
import { useStoreModule } from "~/stores/stores";
import { useInstalledAppsModule } from "~/stores/installedApps";
import type VendorCustomerUserInfoDto from "~/models/VendorCustomerUserInfoDto";

const props = defineProps({
  drawer: Boolean,
});
const emit = defineEmits(["update:drawer"]);
const { locale } = useI18n();

const installedAppsModule = useInstalledAppsModule();
const userModule = useUserModule();
const storeModule = useStoreModule();

const {
  initialized: appsInitialized,
  hasContentManagement,
  hasAnybillEmailMarketing,
  isExternalEmailService,
} = storeToRefs(installedAppsModule);
const {
  initialized: userInitialized,
  isVendorCustomer,
  asVendorCustomer,
  isPOSCustomer,
  isMiscellaneousCustomer,
  vendor,
  isAllProfileComplete: isProfileComplete,
  isOnboarded,
  isActivated,
  user,

} = storeToRefs(userModule);
const { hasStores: isStoreComplete } = storeToRefs(storeModule);

const syncedDrawer = computed({
  get: () => props.drawer,
  set: value => emit("update:drawer", value),
});

let items: any[] = [];

const isInit = computed(() => {
  if (isVendorCustomer.value) { return appsInitialized.value && userInitialized.value; }
  return userInitialized.value;
});

const hideMarketing = computed(
  () =>
    !isVendorCustomer.value
    || (!hasContentManagement.value
    && !hasAnybillEmailMarketing.value
    ),
);

const isAMerchant = computed(
  () =>
    asVendorCustomer.value?.segment === "A"
    || asVendorCustomer.value?.segment === "B",
);

const vendorLogo = computed(() => {
  if (isVendorCustomer.value) {
    return asVendorCustomer.value.vendor.iconResource;
  }
  return "";
});

onMounted(() => {
  // overwrite clicks from usercentrics
  // TODO: create custom component for usercentrics dialog
  window.addEventListener("onUcImprintClick", () => {
    window.location.href = " https://anybill.de/imprint";
  });

  window.addEventListener("onUcImprintClickEnglish", () => {
    window.location.href = "https://anybill.de/en/imprint";
  });

  window.addEventListener("onUcPrivacyClick", () => {
    window.location.href = "https://partner.anybill.de/privacy";
  });

  window.addEventListener("onUcPrivacyClickEnglish", () => {
    window.location.href = "https://partner.anybill.de/en/privacy";
  });
});

watch(
  isInit,
  (init: boolean) => {
    if (init) {
      initData();
      updateAllMarker();
    }
  },
  { immediate: true },
);

watch(
  isProfileComplete,
  () => {
    if (isInit.value) { updateAllMarker(); }
  },
  { immediate: true },
);

watch(
  isStoreComplete,
  () => {
    if (isInit.value) { updateAllMarker(); }
  },
  { immediate: true },
);

watch(
  isOnboarded,
  () => {
    if (isInit.value) { updateAllMarker(); }
  },
  { immediate: true },
);

watch(
  isActivated,
  () => {
    if (isInit.value) { updateAllMarker(); }
  },
  { immediate: true },
);

function initData() {
  items = [
    {
      title: "navigation.performance",
      link: "/dashboard",
      icon: "piechart",
      hide: isMiscellaneousCustomer.value,
      iconWidth: "14",
      isActive: false,
    },
    {
      title: "navigation.customers.onboarding",
      hide: !isMiscellaneousCustomer.value,
      link: "/customer-management/onboarding",
      icon: "piechart",
      iconWidth: "14",
      sanitizedLink: "customer-management/onboarding",
    },
    {
      title: "navigation.customers.self",
      hide: isVendorCustomer.value,
      icon: "usertwo",
      isActive: false,
      items: [
        {
          title: "navigation.customers.management",
          link: "/customer-management#",
          sanitizedLink: "customer-management/:",
        },
        {
          title: "navigation.customers.onboarding",
          link: "/customer-management/onboarding",
          sanitizedLink: "customer-management/onboarding",
        },
      ],
    },
    {
      title: "navigation.distributors",
      link: "/distributor-management",
      icon: "cashregister",
      hide: !isPOSCustomer.value,
      isActive: false,
    },
    {
      title: "navigation.marketing.self",
      hide: hideMarketing.value,
      icon: "megaphone",
      isActive: false,
      items: [
        {
          title: "navigation.marketing.contentManagement",
          link: "/marketing/content-management",
          hidden: !hasContentManagement.value,
        },
        {
          title: "navigation.marketing.customerMails",
          link: "/marketing/customer-mails",
          hidden:
            !hasAnybillEmailMarketing.value || isExternalEmailService.value,
        },
      ],
    },
    {
      title: "navigation.stores",
      link: "/store-management",
      hasMarker: false,
      icon: "shop",
      hide: !isVendorCustomer.value,
      isActive: true,
    },
    {
      title: "navigation.archive.self",
      icon: "archive",
      hide: !isVendorCustomer.value,
      isActive: false,
      items: [
        {
          title: "navigation.archive.receipts",
          link: "/archive/receipts",
        },
        {
          title: "navigation.archive.exports",
          link: "/archive/exports",
        },
      ],
    },
    {
      title: "navigation.packages",
      hasMarker: false,
      link: "/packages",
      icon: "codesandbox",
      hide: isAMerchant.value || !isVendorCustomer.value,
      isActive: false,
    },
    {
      title: "navigation.profile",
      hasMarker: false,
      link: "/profile",
      icon: "user",
      isActive: false,
    },
    {
      title: "navigation.userAdministration",
      link: "/user-administration",
      icon: "usertwo",
      isActive: false,
    },
    {
      title: "navigation.documents",
      link: "/documents",
      icon: "archive",
      hide: isAMerchant.value || !isVendorCustomer.value,
      isActive: false,
    },
    {
      title: "navigation.settings",
      link: "/settings",
      icon: "settings",
      isActive: false,
    },
    {
      title: "navigation.support",
      cb: onSupportLink,
      icon: "helpcircle",
      isActive: false,
    },
    {
      title: "composables.defaultLayout.dataPrivacy",
      cb: openUserCentrics,
      icon: "shield",
      isActive: false,
    },
  ];
}

function execute(item: any) {
  if (item.cb) { item.cb(); }
}

function openUserCentrics() {
  /* let link = "/privacy";

  if (locale.value === "en") { link = `/en${link}`; }

  window.open(link, "_blank", "noreferrer"); */
  (window as any).UC_UI.showSecondLayer();
}

async function onSupportLink() {
  let link = UrlConstants.DRAWER_POS_CUSTOMER_SUPPORT_LINK;
  if (isVendorCustomer) {
    if (locale.value === "en") { link = UrlConstants.DRAWER_VENDOR_CUSTOMER_SUPPORT_LINK_EN; }
    else { link = UrlConstants.DRAWER_VENDOR_CUSTOMER_SUPPORT_LINK_DE; }
  }

  window.open(link, "_blank", "noreferrer");

  await AnybillAnalytics.Instance.sendEvent({
    information: new Map<string, string>()
      .set(
        AnalyticsEventType.INTERACTION,
        AnalyticsInteractionEvent.CLICK_EVENT,
      )
      .set(AnalyticsEventType.REDIRECT_TO, AnalyticsRedirectTarget.HELPDESK)
      .set(AnalyticsEventType.LINK_TO, link),
  });
}

function collapseSubItems() {
  items.forEach((item: any) => {
    if (Object.prototype.hasOwnProperty.call(item, "items")) { item.isActive = false; }
  });
}

function updateMarkerState(link: string, val: boolean) {
  if (isVendorCustomer.value) {
    const indexToUpdate = items.findIndex(item => item.link === link);
    items[indexToUpdate].hasMarker = !val;
  }
}

function updateAllMarker() {
  updateMarkerState("/profile", isProfileComplete.value);
  updateMarkerState("/packages", isOnboarded.value || isActivated.value);
  updateMarkerState("/store-management", isStoreComplete.value);
}
</script>

<template>
  <v-navigation-drawer
    v-model="syncedDrawer"
    class="mainNav"
    width="290"
  >
    <div class="header d-flex">
      <v-img
        v-if="vendorLogo"
        :src="vendorLogo"
        contain
        max-width="200px"
        max-height="40px"
      />
      <v-img
        v-else
        contain
        src="/anybill-logo.svg"
        width="180px"
        height="30px"
      />
    </div>
    <div v-if="isInit">
      <v-list
        flat
        :ripple="false"
      >
        <div
          v-for="(item, i) in items"
          :key="i"
          class="drawer-items"
        >
          <template v-if="!item.hide">
            <!-- item has subnavigation -->
            <template v-if="item.items != null">
              <v-list-group
                :key="i - 1"
                v-model="item.isActive"
                class="pl-5"
              >
                <template #activator="{ props: subGroupProps }">
                  <v-list-item
                    variant="plain"
                    class="pa-0 ma-0"
                    v-bind="subGroupProps"
                  >
                    <v-list-item-title
                      class="title-medium"
                      style="color: #74777e; cursor: pointer;"
                    >
                      <div
                        class="d-flex"
                        style="align-items: center;"
                      >
                        <svg-icon
                          v-if="item.icon"
                          width="16px"
                          height="16px"
                          class="ml-3 mr-2"
                          :name="item.icon"
                        />
                        {{ $t(item.title) }}
                      </div>
                    </v-list-item-title>
                  </v-list-item>
                </template>

                <navigation-item
                  v-for="(subitem, j) in item.items"
                  :key="j"
                  class="subItem"
                  :item="subitem"
                />
              </v-list-group>
            </template>
            <!-- single navigation item -->
            <navigation-item
              v-else
              :key="i"
              v-model="item.isActive"
              :item="item"
              class="singleItem"
              @click="collapseSubItems"
              @handle-button-click="execute(item)"
            />
          </template>
        </div>
      </v-list>
      <v-list class="mt-auto drawer-footer">
        <language-settings show-full-name />
        <div class="footer">
          <user-tile />
        </div>
      </v-list>
    </div>
  </v-navigation-drawer>
</template>

<style lang="scss" scoped>
.smol-subtitle {
  font-size: 0.625em;
  color: grey;
}

.notif-wobble {
  width: 15px;
  height: 15px;
  margin-left: 15px;
  border-radius: 100px;
  background-color: red;
}

.disabled-item {
  background-color: rgb(0, 0, 0, 0.05);
}

.drawer-items {
  padding-right: 8px;
}

.drawer-footer {
  padding-bottom: 0;
  position: relative;
  bottom: 0;
  width: 100%
}
</style>
